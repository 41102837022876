import { apiGatewayClient } from "services/api";

const fetch = (method, target, params = {}) =>
  apiGatewayClient()
    .then((apiGatewayClient) =>
      apiGatewayClient[method](target, {}, params, {})
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error && (error.data || error);
    });

export const setThrottlingLimit = (body) =>
  fetch("post", "/admin/api-throttling", body);
export const createClient = (body) =>
  fetch("post", "/admin/client-onboarding", body);
export const updateApiKey = (env) => fetch("put", `/admin/api-key/${env}`);
export const fetchRestApis = () => fetch("get", "/admin/apis");
export const fetchApiKeys = () => fetch("get", "/admin/api-keys");
export const fetchClientList = (env) => fetch("get", `/admin/clients/${env}`);
export const clientBoardingActivity = (body) => fetch ('post','/admin/client-boarding',body);
export const getEnvList = () => fetch('get', '/environment-list')
// export const onBoardClient
