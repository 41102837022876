import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  Form,
  Input,
  Table,
  Message,
  Button,
} from "semantic-ui-react";
import {
  createClient,
  fetchClientList,
  clientBoardingActivity,
  getEnvList
} from "services/custom-features";
import { cognitoUserPoolId } from "services/api";

const ClientBoarding = () => {
  const [totalEnvOptions, setTotalEnvOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [clientEnv, setClientEnv] = useState("");
  const [clientList, setClientList] = useState([]);
  const [isSelectedClientEnv, setIsSelectedClientEnv] = useState(true);

  const [formData, setFormData] = useState({
    clientName: "",
    clientEmail: "",
    env: "",
    clientUrl: "",
  });

  const [notification, setNotification] = useState({
    responseType: "",
    showMessage: false,
    message: "",
  });

  useEffect(() => {
    async function fetchAndSetEnv () {
      const totalEnvOptions = (await getEnvList() || []).map((item) => ({
        key: item,
        value: item,
        text: item,
      }));
      console.log("Env list for client management section", totalEnvOptions);
      setTotalEnvOptions(totalEnvOptions);
    }
    fetchAndSetEnv()
  }, []);

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const createClientOnboarding = async () => {
    const { clientName, clientEmail, env, clientUrl } = formData;
    const newErrors = {};

    if (!clientName) {
      newErrors.clientName = "Client name is required**";
    }

    if (!clientEmail) {
      newErrors.clientEmail = "Client email is required**";
    }

    if (!env) newErrors.env = "Environment is required";

    if (!clientUrl) newErrors.clientUrl = "Client url is required**";

    if (Object.keys(newErrors).length === 0) {
      const response = await createClient({
        clientName,
        clientEmail,
        env,
        clientUrl,
        userPoolId: cognitoUserPoolId,
      });

      setNotification({
        status: response.status,
        showMessage: true,
        message: response.message,
      });

      setFormData({
        clientName: "",
        clientEmail: "",
        clientUrl: "",
        env: "",
      });
      setErrors({});
    } else {
      // Display validation errors
      setErrors(newErrors);
    }
  };

  const showClientListByEnv = async () => {
    try {
      if (clientEnv) {
        const response = await fetchClientList(clientEnv);
        setNotification({
          status: response.status,
          showMessage: true,
          message: response.message,
        });
        setIsSelectedClientEnv(true);
        setClientList(response.data);
      } else {
        setIsSelectedClientEnv(false)
      }
    } catch (error) {
      setNotification({
        status: "500",
        showMessage: true,
        message: error.message,
      });
    }
  };

  const handleDismiss = () => {
    setNotification({
      status: "",
      showMessage: false,
      message: "",
    });
  };

  const handleClientActivity = async (client) => {
    try{
      const clientBoardingStatus = await clientBoardingActivity({
        clientEnv,
        clientId: client.client_id,
        clientStatus: !client.active,
      });
      const clientListResponse = await fetchClientList(clientEnv);
      setNotification({
        status: clientBoardingStatus.status,
        showMessage: true,
        message: clientBoardingStatus.message,
      });
      setIsSelectedClientEnv(true);
      setClientList(clientListResponse.data);
    } catch (error) {
      setNotification({
        status: "500",
        showMessage: true,
        message: error.message || 'Error while updating client activity status',
      });
    }
  };

  return (
    <Container fluid style={{ padding: "2em" }}>
      {notification.showMessage ? (
        <Message
          onDismiss={handleDismiss}
          header={notification.message}
          color={notification.status === 200 ? "green" : "red"}
        />
      ) : null}
      <Header as="h1">Client Onboarding</Header>

      <Form onSubmit={createClientOnboarding}>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="env"
            required
            label="Environment"
            placeholder="environment"
            search
            selection
            value={formData.env}
            options={totalEnvOptions}
            onChange={handleChange}
            error={
              errors.env
                ? {
                    content: "Please select the env",
                    pointing: "above",
                  }
                : null
            }
          ></Form.Dropdown>
          <Form.Field
            control={Input}
            label="Client Name"
            name="clientName"
            value={formData.clientName}
            placeholder="client name"
            onChange={handleChange}
            error={
              errors.clientName
                ? {
                    content: errors.clientName,
                    pointing: "above",
                  }
                : null
            }
          ></Form.Field>
          <Form.Field
            control={Input}
            label="Client Email"
            name="clientEmail"
            value={formData.clientEmail}
            placeholder="client email"
            onChange={handleChange}
            error={
              errors.clientEmail
                ? {
                    content: errors.clientEmail,
                    pointing: "above",
                  }
                : null
            }
          ></Form.Field>
          <Form.Field
            control={Input}
            name="clientUrl"
            label="Client URL"
            value={formData.clientUrl}
            placeholder="client url"
            onChange={handleChange}
            error={
              errors.clientUrl
                ? {
                    content: errors.clientUrl,
                    pointing: "above",
                  }
                : null
            }
          ></Form.Field>
        </Form.Group>
        <Form.Button
          color="blue"
          style={{ paddingTop: "10px" }}
          content="Onboard"
          onSubmit={createClientOnboarding}
        />
      </Form>

      <div style={{ paddingTop: "4em" }}>
        <Header as="h1">Client List</Header>
        <Form onSubmit={showClientListByEnv} style={{ display: "flex" }}>
          <Form.Group>
            <Form.Dropdown
              name="clientEnv"
              required
              label="Environment"
              placeholder="environment"
              search
              selection
              value={clientEnv}
              options={totalEnvOptions}
              onChange={(e, { value }) => {
                setClientEnv(value)
                setIsSelectedClientEnv(true);
              }}
              error={
                !isSelectedClientEnv
                  ? {
                      content: "Please select the env",
                      pointing: "above",
                    }
                  : null
              }
            ></Form.Dropdown>
          </Form.Group>
          <Form.Button
            color="blue"
            style={{ margin: "25px 10px" }}
            content="Submit"
          />
        </Form>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Client Name</Table.HeaderCell>
              <Table.HeaderCell>Client Email</Table.HeaderCell>
              <Table.HeaderCell>Client Url</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {clientList.length ? (
            <Table.Body>
              {clientList.map((client) => (
                <Table.Row key={client.client_id}>
                  <Table.Cell>{client.client_name}</Table.Cell>
                  <Table.Cell>{client.client_email}</Table.Cell>
                  <Table.Cell>{client.client_url}</Table.Cell>
                  <Table.Cell>
                    <Button
                      style={{ padding: "9px 14px" }}
                      color={client.active ? "red" : "green"}
                      content={client.active ? "Deactivate" : "Activate"}
                      onClick={() => handleClientActivity(client)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          ) : (
            <p
              style={{
                textAlign: "center",
                fontWeight: "normal",
                fontSize: "24px",
                padding: "15px 0",
              }}
            >
              No Data available!
            </p>
          )}
        </Table>
      </div>
    </Container>
  );
};

export default ClientBoarding;
