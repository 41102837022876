import React, { useState, useEffect } from "react";
import {
  Message,
  Container,
  Header,
  Form,
  Input,
  Loader,
} from "semantic-ui-react";
import {
  setThrottlingLimit,
  updateApiKey,
  fetchRestApis,
  fetchApiKeys,
} from "services/custom-features";

const ApiThrottling = () => {
  const [totalApiGateways, setTotalApiGateways] = useState([]);
  const [apiKey, setApiKey] = useState("");
  const [totalStages, setTotalStages] = useState([]);
  const [stageEndpoints, setStageEndpoints] = useState([]);
  const [totalMethods, setTotalMethods] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSelectedApiKey, setIsSelectedApiKey] = useState(true);
  const [restApis, setRestApi] = useState([]);
  const [selectedRestApi, setSelectedRestApi] = useState([]);
  const [totalUsagePlans, setTotalUsagePlans] = useState([]);
  const [totalApiKeys, setTotalApiKeys] = useState([]);

  const [formData, setFormData] = useState({
    rate: "",
    burst: "",
    method: "",
    stage: "",
    resource: "",
    gatewayApi: "",
    usagePlan: "",
  });

  const [notificationResponse, setNotificationResponse] = useState({
    responseType: "",
    showMessage: false,
    message: "",
  });

  const handleStateChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const setGatewayApis = (restApis) => {
    const apiGatewayOptions = restApis.map((item) => ({
      key: item.apiId,
      value: item.apiName,
      text: item.apiName,
    }));
    setTotalApiGateways(apiGatewayOptions);
  };

  const setApiKeys = async (apiKeys) => {
    const totalApiKeys = apiKeys.map((item) => ({
      key: item.id,
      value: item.name,
      text: item.name,
    }));
    setTotalApiKeys(totalApiKeys);
  };

  useEffect(() => {
    async function fetchData() {
      const restApis = await fetchRestApis();
      const apiKeys = await fetchApiKeys();
      setRestApi(restApis.data);
      setGatewayApis(restApis.data);
      setApiKeys(apiKeys.data);
    }
    fetchData();
  }, []);

  const handleUpdateApiThrottling = async (e, { value }) => {
    e.preventDefault();

    const { rate, burst, method, stage, resource, gatewayApi, usagePlan } =
      formData;
    const newErrors = {};

    if (!rate) newErrors.rate = "Api rate is required";

    if (!burst) newErrors.burst = "Api burst is required";

    if (!method) newErrors.method = "Api method is required";

    if (!stage) newErrors.stage = "Api stage is required";

    if (!resource) newErrors.resource = "Api resource is required";

    if (!gatewayApi) newErrors.env = "Gateway Api  is required";

    if (!usagePlan) newErrors.usagePlan = "Usage Plan is required";

    if (Object.keys(newErrors).length === 0) {
      let response;

      const usagePlanId = selectedRestApi[0].usagePlan.filter(
        (item) => item.name === usagePlan
      )[0].id;
      const apiId = selectedRestApi[0].apiId;

      try {
        response = await setThrottlingLimit({
          apiId,
          stage,
          method,
          resource,
          rate,
          burst,
          usagePlanId,
        });
      } catch (error) {
        console.log("Error in method Throttling");
      }

      setNotificationResponse({
        status: response.status,
        showMessage: true,
        message: response.message,
      });

      setFormData({
        rate: "",
        burst: "",
        method: "",
        stage: "",
        resource: "",
        gatewayApi: "",
        usagePlan: "",
      });
      setErrors({});
    } else {
      // Display validation errors
      setErrors(newErrors);
    }
  };

  const handleUpdateApiKey = async (e, { value }) => {
    try {
      e.preventDefault();

      if (!apiKey) setIsSelectedApiKey(false);
      else {
        const apiKeyId = totalApiKeys.find((item) => item.value === apiKey).key;
        const response = await updateApiKey(apiKeyId);
        setNotificationResponse({
          status: response.status,
          showMessage: true,
          message: response.message,
        });
        setApiKey("");
        setIsSelectedApiKey(true);
      }
    } catch (error) {
      setNotificationResponse({
        status: 500,
        showMessage: true,
        message: error.message,
      });
      setApiKey("");
      setIsSelectedApiKey(true);
    }
  };

  const handleStageChange = async (e, { value }) => {
    e.preventDefault();

    const resources = selectedRestApi[0].resources.map((item) => ({
      key: item.id,
      value: item.name,
      text: item.name,
    }));
    setStageEndpoints(resources);
    setFormData({ ...formData, ["stage"]: value });
  };

  const handleEndpointsChange = async (e, { key, value }) => {
    e.preventDefault();
    const methods = selectedRestApi[0].resources
      .filter((item) => item.name === value)[0]
      .method.map((item) => ({
        key: item,
        value: item,
        text: item,
      }));

    setFormData({ ...formData, ["resource"]: value });
    setTotalMethods(methods);
  };

  const handleRestApiChange = async (e, { value }) => {
    const selectedApi = restApis.filter((item) => item.apiName === value);

    const stages = selectedApi[0].stages.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));

    const usagePlans = selectedApi[0].usagePlan.map((item) => ({
      key: item.id,
      value: item.name,
      text: item.name,
    }));

    setTotalUsagePlans(usagePlans);
    setSelectedRestApi(selectedApi);
    setTotalStages(stages);
    setFormData({ ...formData, ["gatewayApi"]: value });
  };

  const handleApiKeyChange = async (e, { value }) => {
    e.preventDefault();
    setApiKey(value);
  };

  const handleDismiss = () => {
    setNotificationResponse({
      status: "",
      showMessage: false,
      message: "",
    });
  };

  return (
    <>
      {notificationResponse.showMessage ? (
        <Message
          onDismiss={handleDismiss}
          header={notificationResponse.message}
          color={notificationResponse.status === 200 ? "green" : "red"}
        />
      ) : null}

      {restApis.length ? (
        <Container
          fluid
          style={{
            padding: "2em 5em",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Form onSubmit={handleUpdateApiThrottling} style={{ width: "280px" }}>
            <Header as="h1">Api Throttling </Header>
            <Form.Dropdown
              required
              label="Gateway Apis"
              placeholder="apis"
              search
              selection
              value={formData.gatewayApi}
              options={totalApiGateways}
              onChange={handleRestApiChange}
              error={
                errors.env
                  ? {
                      content: errors.env,
                      pointing: "below",
                    }
                  : null
              }
            ></Form.Dropdown>
            <Form.Dropdown
              required
              label="Usage Plan"
              placeholder="usage plan"
              search
              selection
              value={formData.usagePlan}
              options={totalUsagePlans}
              onChange={(e, { value }) => handleStateChange("usagePlan", value)}
              error={
                errors.stage
                  ? {
                      content: errors.stage,
                      pointing: "below",
                    }
                  : null
              }
            ></Form.Dropdown>
            <Form.Dropdown
              required
              label="Stage"
              placeholder="stage"
              search
              selection
              value={formData.stage}
              options={totalStages}
              onChange={handleStageChange}
              error={
                errors.stage
                  ? {
                      content: errors.stage,
                      pointing: "below",
                    }
                  : null
              }
            ></Form.Dropdown>
            <Form.Dropdown
              required
              label="Resource"
              placeholder="resource"
              search
              fluid
              selection
              value={formData.resource}
              options={stageEndpoints}
              onChange={handleEndpointsChange}
              error={
                errors.resource
                  ? {
                      content: errors.resource,
                      pointing: "below",
                    }
                  : null
              }
            ></Form.Dropdown>
            <Form.Dropdown
              required
              label="Method"
              placeholder="method"
              search
              fluid
              selection
              value={formData.method}
              options={totalMethods}
              onChange={(e, { value }) => handleStateChange("method", value)}
              error={
                errors.method
                  ? {
                      content: errors.method,
                      pointing: "below",
                    }
                  : null
              }
            ></Form.Dropdown>
            <Form.Field
              control={Input}
              label="Rate"
              value={formData.rate}
              placeholder="100"
              onChange={(e, { value }) => handleStateChange("rate", value)}
              error={
                errors.rate
                  ? {
                      content: errors.rate,
                      pointing: "below",
                    }
                  : null
              }
            ></Form.Field>
            <Form.Field
              control={Input}
              value={formData.burst}
              label="Burst"
              placeholder="200"
              onChange={(e, { value }) => handleStateChange("burst", value)}
              error={
                errors.burst
                  ? {
                      content: errors.burst,
                      pointing: "below",
                    }
                  : null
              }
            >
            </Form.Field>
            <Form.Button color="blue" content="Save" />
          </Form>

          <Form onSubmit={handleUpdateApiKey} style={{ width: "280px" }}>
            <Header as="h1">Update Api key</Header>
            <Form.Dropdown
              required
              label="Api Keys"
              placeholder="api keys"
              search
              selection
              value={apiKey}
              options={totalApiKeys}
              onChange={handleApiKeyChange}
              error={
                isSelectedApiKey
                  ? null
                  : {
                      content: "Environment is required",
                      pointing: "below",
                    }
              }
            ></Form.Dropdown>
            <Form.Button color="blue" content="Update" />
          </Form>
        </Container>
      ) : (
        <Loader active size="medium">
          {restApis.length ? (
            <>
              Please wait a minute and try refreshing. If this doesn't work,
              please contact the admin for assistance.
            </>
          ) : null}
        </Loader>
      )}
    </>
  );
};
export default ApiThrottling;
