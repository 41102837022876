// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

// swagger-ui
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";

// semantic-ui
import { Container, Header, Icon, Loader } from "semantic-ui-react";

// services
import { getApi } from "services/api-catalog";

// state
import { store } from "services/state.js";
import { observer } from "mobx-react";

export default observer(
  class ApisPage extends React.Component {
    containerRef = React.createRef();
    hasRoot = false;

    componentDidMount() {
      this.updateApi(true);
    }
    componentDidUpdate() {
      this.updateApi(false);
    }
    componentWillUnmount() {
      this.containerRef = null;
    }

    updateApi(isInitial) {
      return getApi(
        this.props.match.params.apiId || "ANY",
        true,
        this.props.match.params.stage,
        isInitial
      ).then((api) => {
        if (this.containerRef == null) return;
        const elem = this.containerRef.current;

        if (api && elem != null) {

          window.Buffer = window.Buffer || require("buffer").Buffer;
          let spec =
            api.swagger["x-amazon-apigateway-documentation"]
              .documentationParts[0].properties;

          const swaggerUiConfig = {
            domNode: this.containerRef.current,
            spec: spec,
          };

          SwaggerUI(swaggerUiConfig);
        }
      });
    }

    render() {
      let errorHeader;
      let errorBody;

      if (store.apiList.loaded) {
        if (!store.apiList.apiGateway.length && !store.apiList.generic.length) {
          errorHeader = "No APIs Published";
          errorBody =
            "Your administrator hasn't added any APIs to your account. Please contact them to publish an API.";
        } else if (!store.api) {
          errorHeader = "No Such API";
          errorBody = "The selected API doesn't exist.";
        }
      }

      return (
        <>
          {store.apiList.loaded ? (
            <div ref={this.containerRef}>
              {errorHeader && errorBody && (
                <>
                  <Header
                    as="h2"
                    icon
                    textAlign="center"
                    style={{ padding: "40px 0px" }}
                  >
                    <Icon name="warning sign" circular />
                    <Header.Content>{errorHeader}</Header.Content>
                  </Header>
                  <Container text textAlign="justified">
                    <p>{errorBody}</p>
                  </Container>
                </>
              )}
            </div>
          ) : (
            <Loader active size="medium" />
          )}
        </>
      );
    }
  }
);
